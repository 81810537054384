<template>
    <div class="auth-page">
        <header id="page-topbar">
            <div class="navbar-header">
                <div class="d-flex">
                    <!-- LOGO -->
                    <div class="navbar-brand-box">
                        <a href="index.html" class="logo logo-dark">
                            <span class="logo-sm">
                                <img src="@/assets/images/logomroyeksm.png" alt height="22" />
                            </span>
                            <span class="logo-lg">
                                <img src="@/assets/images/logoabuabu.png" alt height="40" />
                            </span>
                        </a>

                        <a href="index.html" class="logo logo-light">
                            <span class="logo-sm">
                                <img src="@/assets/images/logomroyeksm.png" alt height="22" />
                            </span>
                            <span class="logo-lg">
                                <img src="@/assets/images/logoputih.png" alt height="40" />
                            </span>
                        </a>
                    </div>

                </div>

                <div class="d-flex">
                    <div class="dropdown d-inline-block d-lg-none ml-2">
                        <button type="button" class="btn header-item noti-icon waves-effect"
                            id="page-header-search-dropdown" data-toggle="dropdown" aria-haspopup="true"
                            aria-expanded="false">
                            <i class="ri-search-line"></i>
                        </button>
                        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"
                            aria-labelledby="page-header-search-dropdown">
                            <form class="p-3">
                                <div class="form-group m-0">
                                    <div class="input-group">
                                        <input type="text" class="form-control" placeholder="Search ..." />
                                        <div class="input-group-append">
                                            <button class="btn btn-primary" type="submit">
                                                <i class="ri-search-line"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>





                    <b-dropdown right variant="black" toggle-class="header-item" class="d-inline-block user-dropdown">
                        <template v-slot:button-content>
                            <!-- <span class="rounded-circle header-profile-user rounded-circle bg-light text-body">{{
                                users.nama.charAt(0) }}</span> -->
                            <img class="rounded-circle header-profile-user" :src="users.avatar" alt="Header Avatar" />
                            <span class="d-none d-xl-inline-block ml-1">{{ users.nama }}</span>
                            <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
                        </template>
                        <a class="dropdown-item" href="/profile">
                            <i class="ri-user-line align-middle mr-1"></i>
                            {{ $t('navbar.dropdown.kevin.list.profile') }}
                        </a>
                        <!-- item-->
                        <!-- <a class="dropdown-item" href="#">
            <i class="ri-user-line align-middle mr-1"></i>
            {{ $t('navbar.dropdown.kevin.list.profile') }}
          </a>
          <a class="dropdown-item" href="#">
            <i class="ri-wallet-2-line align-middle mr-1"></i>
            {{ $t('navbar.dropdown.kevin.list.mywallet') }}
          </a>
          
          <a class="dropdown-item" href="#">
            <i class="ri-lock-unlock-line align-middle mr-1"></i>
            {{ $t('navbar.dropdown.kevin.list.lockscreen') }}
          </a> -->
                        <!-- <div class="dropdown-divider"></div> -->
                        <a class="dropdown-item text-danger" type="button" @click="logout()">
                            <i class="ri-shut-down-line align-middle mr-1 text-danger"></i>
                            {{ $t('navbar.dropdown.kevin.list.logout') }}
                        </a>
                    </b-dropdown>

                    <!-- <div class="dropdown d-inline-block">
                <button
                  type="button"
                  class="btn header-item noti-icon right-bar-toggle waves-effect toggle-right"
                  @click="toggleRightSidebar"
                >
                  <i class="ri-settings-2-line toggle-right"></i>
                </button>
              </div> -->
                </div>
            </div>
        </header>
        <b-container style="margin-top: 90px;">
            <h2 class="auth-logo" style="text-align: center">
                Choose the Project
            </h2>
            <b-row class="mt-4">
                <b-col md="4" sm="6" v-for="(data, idx) in dataProject" :key="idx">
                    <div class="text-white-50 card bg-primary">
                        <div class="card-body">
                            <!-- <div class="mb-4 text-white card-title"><i class="mdi mdi-bullseye-arrow me-3"></i>

                            </div> -->
                            <h3 variant="link" v-if="data.variable_tampil.nama_proyek == true"
                                class="fw-semi-bold text-white" v-b-tooltip.hover :title="data.nama_proyek">
                                <span v-if="data.nama_proyek.length > 60">
                                    {{ data.nama_proyek.substr(0, 60) + '...' }}</span>
                                <span v-else>
                                    {{ data.nama_proyek }}
                                </span>
                            </h3>
                            <span variant="link" v-if="data.variable_tampil.client == true"
                                class="fw-semi-bold text-white">{{
                                    data.kode_proyek }} - <strong>{{ data.client }}</strong></span>
                            <hr />
                            <div v-if="data.variable_tampil.progres == true" class="mb-3">
                                <b-progress :value="data.progres" :max="100" show-value height="1rem"
                                    variant="warning"></b-progress>
                            </div>
                            <b-row v-if="data.variable_tampil.waktu_mulai == true">
                                <b-col cols="4"><span class="fw-bold text-white"><strong>Start
                                            Project</strong></span></b-col>
                                <b-col cols="6" class="text-white">: {{ new
                                    Date(data.waktu_mulai).toLocaleDateString('id-ID') }}</b-col>
                            </b-row>
                            <b-row v-if="data.variable_tampil.waktu_selesai == true">
                                <b-col cols="4"><span class="fw-bold text-white"><strong>End Project</strong></span></b-col>
                                <b-col cols="6" class="text-white">: {{ new
                                    Date(data.waktu_selesai).toLocaleDateString('id-ID')
                                }}</b-col>
                            </b-row>

                            <br>
                            <!-- <div class="mb-3 mt-3">
                            {{ data.client }}
                        </div> -->
                            <b-button v-if="data.level == 0" class="btn-rounded-f mr-1" variant="light"
                                @click="getProject(data.id_proyek)">Edit</b-button>
                            <b-button class="btn-rounded-f" variant="success"
                                @click="$router.push('/project/dashboard'); getId(data.id_proyek, data)">Open</b-button>
                        </div>
                    </div>
                    <!-- <b-card class="border-0">
                        <h3 variant="link" v-if="data.variable_tampil.nama_proyek == true" class="fw-semi-bold text-muted">
                            {{ data.nama_proyek }}</h3>
                        <span variant="link" v-if="data.variable_tampil.client == true" class="fw-semi-bold text-muted">{{
                            data.kode_proyek }} - <strong>{{ data.client }}</strong></span>
                        <hr />
                        <div v-if="data.variable_tampil.progres == true" class="mb-3">
                            <b-progress :max="100">
                                <b-progress-bar :value="data.proges" :label="`${((data.proges / 100) * 100).toFixed(2)}%`">
                                </b-progress-bar>
                            </b-progress>
                        </div>
                        <b-row v-if="data.variable_tampil.waktu_mulai == true">
                            <b-col cols="4"><span class="fw-semi-bold">Waktu Mulai</span></b-col>
                            <b-col cols="6">: {{ new Date(data.waktu_mulai).toLocaleDateString('id-ID', options) }}</b-col>
                        </b-row>
                        <b-row v-if="data.variable_tampil.waktu_selesai == true">
                            <b-col cols="4"><span class="fw-semi-bold">Waktu Selesai</span></b-col>
                            <b-col cols="6">: {{ new Date(data.waktu_selesai).toLocaleDateString('id-ID', options)
                            }}</b-col>
                        </b-row>

                        <br>
                        <b-button class="btn-rounded-f" variant="success"
                            @click="$router.push('/project/dashboard'); getId(data.id_proyek, data)">Buka</b-button>
                    </b-card> -->
                </b-col>
                <b-col md="4" sm="6">

                    <b-card class="border-0" @click="$bvModal.show('bv-modal-example')">

                        <div class="d-flex justify-content-center" height="100%">

                            <span> <i class="fa fa-plus" /> Add Project</span>

                        </div>
                    </b-card>
                </b-col>
            </b-row>


        </b-container>

        <b-modal id="bv-modal-example" hide-footer size="lg" body-bg-variant="white">
            <template #modal-title>
                Create Project
            </template>
            <b-form-group>
                <b-form row>
                    <b-form-group horizontal label="Project Name" label-for="name-field" label-class="text-md-right"
                        :label-cols="4" breakpoint="md">
                        <b-form-input type="text" v-model="name" id="name-field" />
                    </b-form-group>
                    <b-form-group horizontal label="Code" label-for="code-field" label-class="text-md-right" :label-cols="4"
                        breakpoint="md">
                        <b-form-input type="text" v-model="code" id="code-field" />
                    </b-form-group>

                    <b-form-group horizontal label="Project Value" label-for="value-field" label-class="text-md-right"
                        :label-cols="4" breakpoint="md">
                        <MyCurrency v-model="value"></MyCurrency>
                    </b-form-group>

                    <b-form-group horizontal label="Date" label-for="value-field" label-class="text-md-right"
                        :label-cols="4" breakpoint="md">
                        <b-row>
                            <b-col cols="5">
                                <DatePicker lang="en" :placeholder="datepickerStart" input-class="form-control"
                                    @change="selectDateStart">
                                    <i class="glyphicon glyphicon-th" slot="calendar-icon" />
                                </DatePicker>
                            </b-col>
                            <b-col cols="2">
                                <div class="mt-1 text-center">to</div>
                            </b-col>
                            <b-col cols="5">
                                <DatePicker lang="en" :placeholder="datepickerEnd" input-class="form-control"
                                    @change="selectDateEnd">
                                    <i class="glyphicon glyphicon-th" slot="calendar-icon" />
                                </DatePicker>
                            </b-col>
                        </b-row>
                    </b-form-group>

                    <b-form-group horizontal label="Client" label-for="client-field" label-class="text-md-right"
                        :label-cols="4" breakpoint="md">
                        <b-form-input v-model="client" type="text" id="client-field" />
                    </b-form-group>

                    <b-form-group horizontal label="Project Description" label-for="autosize-textarea"
                        label-class="text-md-right" :label-cols="4" breakpoint="md">
                        <textarea v-model="description" class="form-control" name="textarea" rows="5"></textarea>
                    </b-form-group>

                    <b-form-group horizontal label="Weight" label-for="autosize-textarea" label-class="text-md-right"
                        :label-cols="4" breakpoint="md">
                        <v-select v-model="bobot" :reduce="user => user.value" label="text" :options="optionsBobot" />
                    </b-form-group>
                    <b-form-group horizontal label="Time Report" label-for="autosize-textarea" label-class="text-md-right"
                        :label-cols="4" breakpoint="md">
                        <v-select v-model="time_report" :reduce="user => user.value" label="text" :options="optionsBobot" />
                    </b-form-group>
                    <b-form-group horizontal label="Working Days" label-for="autosize-textarea" label-class="text-md-right"
                        :label-cols="4" breakpoint="md">
                        <v-select v-model="hari_kerja" multiple :reduce="user => user.value" label="text"
                            :options="optionsHari" />
                    </b-form-group>
                    <b-form-group horizontal label="Logo" label-for="autosize-textarea" label-class="text-md-right"
                        :label-cols="4" breakpoint="md">
                        <b-form-file v-model="filelogo" placeholder="Choose a file your logo"
                            drop-placeholder="Drop file here..."></b-form-file>

                    </b-form-group>
                </b-form>
            </b-form-group>
            <div class="modal-footer">
                <b-button variant="success text-white" type="submit" class="me-3" @click="createProject">Save
                </b-button>
                <button type="button" class="btn btn-secondary" data-dismiss="modal"
                    @click="$bvModal.hide('bv-modal-example')">Close</button>
            </div>


        </b-modal>

        <b-modal id="bv-modal-edit" hide-footer size="lg" body-bg-variant="white">
            <template #modal-title>
                Edit Project
            </template>
            <b-form-group>
                <b-form row>
                    <b-form-group horizontal label="Project Name" label-for="name-field" label-class="text-md-right"
                        :label-cols="4" breakpoint="md">
                        <b-form-input type="text" v-model="name" id="name-field" />
                    </b-form-group>
                    <b-form-group horizontal label="Code" label-for="code-field" label-class="text-md-right" :label-cols="4"
                        breakpoint="md">
                        <b-form-input type="text" v-model="code" id="code-field" />
                    </b-form-group>

                    <b-form-group horizontal label="Project Value" label-for="value-field" label-class="text-md-right"
                        :label-cols="4" breakpoint="md">
                        <MyCurrency v-model="value"></MyCurrency>
                    </b-form-group>

                    <b-form-group horizontal label="Date" label-for="value-field" label-class="text-md-right"
                        :label-cols="4" breakpoint="md">
                        <b-row>
                            <b-col cols="5">
                                <DatePicker lang="en" :placeholder="datepickerStart" input-class="form-control"
                                    @change="selectDateStart">
                                    <i class="glyphicon glyphicon-th" slot="calendar-icon" />
                                </DatePicker>
                            </b-col>
                            <b-col cols="2">
                                <div class="mt-1 text-center">to</div>
                            </b-col>
                            <b-col cols="5">
                                <DatePicker lang="en" :placeholder="datepickerEnd" input-class="form-control"
                                    @change="selectDateEnd">
                                    <i class="glyphicon glyphicon-th" slot="calendar-icon" />
                                </DatePicker>
                            </b-col>
                        </b-row>
                    </b-form-group>

                    <b-form-group horizontal label="Client" label-for="client-field" label-class="text-md-right"
                        :label-cols="4" breakpoint="md">
                        <b-form-input v-model="client" type="text" id="client-field" />
                    </b-form-group>

                    <b-form-group horizontal label="Project Description" label-for="autosize-textarea"
                        label-class="text-md-right" :label-cols="4" breakpoint="md">
                        <textarea v-model="description" class="form-control" name="textarea" rows="5"></textarea>
                    </b-form-group>

                    <b-form-group horizontal label="Weight" label-for="autosize-textarea" label-class="text-md-right"
                        :label-cols="4" breakpoint="md">
                        <v-select v-model="bobot" :reduce="user => user.value" label="text" :options="optionsBobot" />
                    </b-form-group>
                    <b-form-group horizontal label="Time Report" label-for="autosize-textarea" label-class="text-md-right"
                        :label-cols="4" breakpoint="md">
                        <v-select v-model="time_report" :reduce="user => user.value" label="text" :options="optionsBobot" />
                    </b-form-group>
                    <b-form-group horizontal label="Working Days" label-for="autosize-textarea" label-class="text-md-right"
                        :label-cols="4" breakpoint="md">
                        <v-select v-model="hari_kerja" multiple :reduce="user => user.value" label="text"
                            :options="optionsHari" />
                    </b-form-group>
                    <b-form-group horizontal label="Logo" label-for="autosize-textarea" label-class="text-md-right"
                        :label-cols="4" breakpoint="md">
                        <b-form-file v-model="filelogo" placeholder="Choose a file your logo"
                            drop-placeholder="Drop file here..."></b-form-file>
                            <br>
                        <b-img v-bind="mainProps" rounded :src="this.logo_file" alt="Left image"></b-img>
                    </b-form-group>
                </b-form>
            </b-form-group>
            <div class="modal-footer">
                <b-button variant="success text-white" type="submit" class="me-3" @click="editProject">Save
                </b-button>
                <button type="button" class="btn btn-secondary" data-dismiss="modal"
                    @click="$bvModal.hide('bv-modal-edit')">Close</button>
            </div>


        </b-modal>

        <b-modal id="bv-modal-notification" title="Information!" header-bg-variant="primary">
            <div class="py-3 text-center">
                <i class="ni ni-bell-55 ni-3x"></i>
                <h4 class="heading mt-4">Informasi</h4>
                <p>Akun belum subscribe, silahkan pilih paket untuk subscribe</p>
            </div>

            <template #modal-footer>
                <b-button variant="primary" @click="$router.push('/paket')">OK</b-button>
                <b-button text-color="white" class="ml-auto" @click="$bvModal.hide('bv-modal-notification')">
                    Close
                </b-button>
            </template>
        </b-modal>


        <button type="button" class="btn btn-primary float" @click="$router.push('/paket')">Subscription</button>

        <!-- <footer class="auth-footer">
            2020 &copy; Mroyek.id
        </footer> -->

    </div>
</template>
  
<script>
import Vue from 'vue';
import { BCard, BRow, BCol } from 'bootstrap-vue';
// import Widget from '@/components/Widget/Widget';
import DatePicker from 'vue2-datepicker';
import axios from 'axios';
import Swal from "sweetalert2";
import vSelect from 'vue-select'
import MyCurrency from "./my-currency-input.vue";

export default {
    name: 'LoginExample',
    components: { BCard, BRow, BCol, DatePicker, vSelect, MyCurrency },
    data() {
        return {
            baseapi: localStorage.getItem("baseapi"),
            users: JSON.parse(localStorage.getItem('user')),
            id_proyek: null,
            datepickerStart: '',
            datepickerEnd: '',
            name: null,
            code: null,
            value: null,
            client: null,
            description: null,
            dataProject: [],
            showmodal: true,
            bobot: null,
            time_report: null,
            filelogo: null,
            mainProps: {height: 75, class: 'mt-3' },
            logo_file: null,
            optionsBobot: [
                { value: true, text: 'Yes' },
                { value: false, text: 'No' }
            ],
            optionsHari: [
                { value: 0, text: 'Sunday' },
                { value: 1, text: 'Monday' },
                { value: 2, text: 'Tuesday' },
                { value: 3, text: 'Wednesday' },
                { value: 4, text: 'Thursday' },
                { value: 5, text: 'Friday' },
                { value: 6, text: 'Saturday' }
            ],
            hari_kerja: [],
        }
    },
    mounted() {

        this.loadData();
    },
    methods: {
        selectDateStart(val) {
            Vue.set(this, 'datepickerStart', val.toLocaleDateString());
        },
        selectDateEnd(val) {
            Vue.set(this, 'datepickerEnd', val.toLocaleDateString());
        },
        loadData() {
            axios.get(this.baseapi + 'api/auth/user_detail?filter=id_user,=,' + this.users.id, {
                headers: {
                    Authorization: `Bearer ${this.users.accessToken}`,
                }
            }).then(response => {
                // const user = response.data.data;

                // if (user.berlangganan.tipe == "Regular") {
                //     this.$bvModal.show('bv-modal-notification')
                // } else {
                //     this.$bvModal.hide('bv-modal-notification')

                // }
                // console.log(response.data)

                this.loadDataProyek();
                return response

            }).catch(err => {
                console.log(err)
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Terjadi kesalahan, silahkan login kembali!',
                }).then((result) => {
                    if (result.isConfirmed) {
                        localStorage.removeItem("user")
                        localStorage.removeItem("proyek")
                        localStorage.removeItem("idProyek")
                        this.$router.push('/logout');
                    }
                })


            })


        },

        getId(id, data) {
            localStorage.setItem('proyek', JSON.stringify(data));
            localStorage.setItem('idProyek', id)
        },

        loadDataProyek() {
            axios.get(this.baseapi + 'api/assign_member?filter=id_user,=,' + this.users.id, {
                headers: {
                    Authorization: `Bearer ${this.users.accessToken}`,
                }
            }).then(response => {
                this.dataProject = response.data.data
                console.log(response.data)
            })
        },
        createProject() {
            let formData = new FormData();
            formData.append('id_user', this.users.id);
            formData.append('kode_proyek', this.code);
            formData.append('nama_proyek', this.name);
            formData.append('nilai_proyek', this.value);
            formData.append('waktu_mulai', this.datepickerStart);
            formData.append('waktu_selesai', this.datepickerEnd);
            formData.append('client', this.client);
            formData.append('deskripsi_proyek', this.description);
            formData.append('hari_kerja', this.hari_kerja);
            formData.append('bobot', this.bobot);
            formData.append('time_report', this.time_report);
            formData.append('file', this.filelogo);
            // const project = {
            //     id_user: this.users.id,
            //     kode_proyek: this.code,
            //     nama_proyek: this.name,
            //     nilai_proyek: this.value,
            //     waktu_mulai: this.datepickerStart,
            //     waktu_selesai: this.datepickerEnd,
            //     client: this.client,
            //     deskripsi_proyek: this.description,
            //     hari_kerja: this.hari_kerja,
            //     bobot: this.bobot,
            //     time_report: this.time_report,
            // }
            axios.post(this.baseapi + 'api/proyek', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${this.users.accessToken}`,
                }
            }).then(response => {
                console.log(response)
                this.$bvModal.hide('bv-modal-example');
                this.loadData()
                Swal.fire("Success", "Successfully added project!", "success");

                this.datepickerStart = '';
                this.datepickerEnd = '';
                this.name = null;
                this.code = null;
                this.value = null;
                this.client = null;
                this.description = null;
                this.hari_kerja = [];
                this.bobot = null
                this.time_report = null
                this.filelogo = null

            }).catch((err) => {
                console.log(err)
                Swal.fire("Failed", err.response.data.message, "error");

            })
        },
        convertHari(data) {
            for (let index = 0; index < data.length; index++) {
                console.log(data[index])
                if (data[index] == 0) {
                    this.hari_kerja.push({
                        value: 0,
                        text: "Sunday"
                    })
                } else if (data[index] == 1) {
                    this.hari_kerja.push({
                        value: 1,
                        text: "Monday"
                    })
                } else if (data[index] == 2) {
                    this.hari_kerja.push({
                        value: 2,
                        text: "Tuesday"
                    })
                } else if (data[index] == 3) {
                    this.hari_kerja.push({
                        value: 3,
                        text: "Wednesday"
                    })
                } else if (data[index] == 4) {
                    this.hari_kerja.push({
                        value: 4,
                        text: "Thursday"
                    })
                } else if (data[index] == 5) {
                    this.hari_kerja.push({
                        value: 5,
                        text: "Friday"
                    })
                } else if (data[index] == 6) {
                    this.hari_kerja.push({
                        value: 6,
                        text: "Saturday"
                    })
                }


            }
        },
        getProject(id) {
            axios.get(this.baseapi + 'api/proyek/' + id, {
                headers: {
                    Authorization: `Bearer ${this.users.accessToken}`,
                }
            }).then(response => {
                const resHari = response.data.data.hari_kerja

                this.convertHari(resHari)

                this.id_proyek = id

                this.name = response.data.data.nama_proyek
                this.code = response.data.data.kode_proyek
                this.value = response.data.data.nilai_proyek
                this.client = response.data.data.client
                this.description = response.data.data.deskripsi_proyek
                this.datepickerStart = response.data.data.waktu_mulai
                this.datepickerEnd = response.data.data.waktu_selesai
                this.bobot = response.data.data.bobot
                this.time_report = response.data.data.time_report
                this.logo_file = response.data.data.file_logo
                this.$bvModal.show('bv-modal-edit')
            })
        },
        editProject() {
            // const project = {
            //     kode_proyek: this.code,
            //     nama_proyek: this.name,
            //     nilai_proyek: this.value,
            //     waktu_mulai: this.datepickerStart,
            //     waktu_selesai: this.datepickerEnd,
            //     client: this.client,
            //     deskripsi_proyek: this.description,
            //     hari_kerja: this.hari_kerja,
            //     bobot: this.bobot,
            //     time_report: this.time_report,
            // }
            let formData = new FormData();
            formData.append('id_user', this.users.id);
            formData.append('kode_proyek', this.code);
            formData.append('nama_proyek', this.name);
            formData.append('nilai_proyek', this.value);
            formData.append('waktu_mulai', this.datepickerStart);
            formData.append('waktu_selesai', this.datepickerEnd);
            formData.append('client', this.client);
            formData.append('deskripsi_proyek', this.description);
            formData.append('hari_kerja', this.hari_kerja);
            formData.append('bobot', this.bobot);
            formData.append('time_report', this.time_report);
            if(this.filelogo != null){
                formData.append('file', this.filelogo);
            }
            
            axios.post(this.baseapi + 'api/proyek/' + this.id_proyek, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${this.users.accessToken}`,
                }
            }).then(response => {

                console.log(response)
                this.$bvModal.hide('bv-modal-edit');
                this.loadData()
                Swal.fire("Success", "Successfully edit project!", "success");

                this.resetForm()

            }).catch((err) => {
                console.log(err)
                Swal.fire("Failed", err.response.data.message, "error");

            })
        },
        resetForm() {
            this.datepickerStart = '';
            this.datepickerEnd = '';
            this.name = null;
            this.code = null;
            this.value = null;
            this.client = null;
            this.description = null;
            this.hari_kerja = [];
            this.bobot = null
            this.time_report = null
            this.filelogo = null
        },
        logout() {
            Swal.fire({
                title: 'Logout',
                text: "Are you sure to logout from the system?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
            }).then((result) => {
                if (result.isConfirmed) {
                    localStorage.removeItem("user")
                    localStorage.removeItem("proyek")
                    localStorage.removeItem("idProyek")
                    this.$router.push('/logout');
                }
            })
        },
    },
};
</script>
<style>
.float {
    position: fixed;
    bottom: 40px;
    right: 40px;
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    box-shadow: 2px 2px 3px #999;
}
</style>